import { get } from 'lodash';
import { retrieveListOrObject, retrieveObjectMapper } from '../..';

export const modifyParentSurvey = (response = {}, child = {}) => {
  const data = response?.data;
  const childClassAt = retrieveListOrObject(child, false);
  return {
    data: {
      getSurveyForParent: {
        closureDate: data?.closure_date,
        childSurveys: (data?.child_surveys || []).map(item => ({
          child: {
            ...retrieveObjectMapper(item?.child, [
              'ID:id',
              'firstname',
              'lastname',
              'fullname',
              'imageKey:image_key',
              'imageKeyPresignedURL:image_key_presigned_url',
            ]),
            childClassAt: {
              class: retrieveObjectMapper(childClassAt[item?.child?.id], [
                'ID:id',
                'label',
              ]),
            },
          },
          centreLabel: item?.centre_label,
          childLevel: item?.child_level,
          lastUpdateAt: item?.last_update_at,
          completedAt: item?.completed_at,
          surveyLink: item?.survey_link,
          ...(item?.last_update_by && {
            lastUpdateBy: retrieveObjectMapper(item?.last_update_by, [
              'ID:id',
              'firstname',
              'lastname',
              'email',
              'image_key',
            ]),
          }),
          ...(item?.completed_by && {
            completedBy: retrieveObjectMapper(item?.completed_by, [
              'ID:id',
              'firstname',
              'lastname',
              'email',
              'image_key',
            ]),
          }),
        })),
      },
    },
  };
};
export const modifyConfigResponse = (response = {}) => {
  return {
    totalCount: response?.data?.totalCount || 0,
    data: {
      getSurveyNotificationConfig: {
        data: retrieveListOrObject(response).map(item =>
          retrieveObjectMapper(item, [
            'ID:id',
            'key',
            'value',
            'active',
            'created_at',
            'created_by',
          ])
        ),
      },
    },
  };
};

export const transformMsQuestions = (response = []) => {
  return {
    data: {
      getSurveyQuestions: response.map(item => {
        return {
          ID: get(item, 'id'),
          answer: get(item, 'answer'),
          answerID: get(item, 'answer_id'),
          isAllowMultiple: get(item, 'is_allow_multiple'),
          question: get(item, 'question'),
          questionNo: get(item, 'question_section_id'),
          questionType: get(item, 'question_type'),
          response: get(item, 'response', []),
          responseID: get(item, 'response_id', []),
          sectionLabel: get(item, 'section_label'),
          sectionTitle: get(item, 'section_title'),
          questionSectionId: get(item, 'question_section_id'),
        };
      }),
    },
  };
};
