import { AxiosHelper } from '..';

export const MS_PHASE_2C_PIM_PSS_ENPOINT = {
  LIST_PIM_CENTRE_DETAILS: '/pim-centre/list-pim-centre-details',
  GET_PIM_CENTRE_DETAILS: '/pim-centre/get-pim-centre-details',
  GET_PARENT_SURVEY: '/satisfaction-survey/get-survey-for-parent',
  SURVEY_NOTIFICATION_CONFIG:
    '/satisfaction-survey/get-survey-notification-config',
  SURVEY_DETAILS_FROM_SURVEY_UUID:
    '/satisfaction-survey/get-survey-details-from-survey-uuid',
  GET_SURVEY_CONFIG: '/satisfaction-survey/get-survey-config',
  GET_SURVEY_QUESTIONS: '/satisfaction-survey/get-survey-questions',
  UPDATE_SURVEY_RESPONSE: '/satisfaction-survey/update-survey-response',
};
export const parentInteractionService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_PIM_SVC_URL });
export const parentSurveyService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_PSS_SVC_URL });
