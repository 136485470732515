import { SplitContext, useClient } from '@splitsoftware/splitio-react';
import { useContext, useEffect, useState } from 'react';

/**
 * custom hooks for feature flag
 * @param {*} featureFlag param for feature flag key config
 * @param {*} { updateOnChange } the config for listen event update realtime when sdk update,
 * default to be true
 * @returns
 */
const useFeatureFlag = (featureFlag = '', { updateOnChange = true } = {}) => {
  const { isReady } = useContext(SplitContext);
  const [treatment, setTreatment] = useState('control');
  const client = useClient();

  useEffect(() => {
    if (client) {
      setTreatment(client.getTreatment(featureFlag));

      if (updateOnChange) {
        const listener = () => setTreatment(client.getTreatment(featureFlag));
        client.on(client.Event.SDK_UPDATE, listener);
        return () => {
          client.off(client.Event.SDK_UPDATE, listener);
        };
      }
    }
  }, [isReady]);

  return treatment;
};
export default useFeatureFlag;
