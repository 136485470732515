import moment from 'moment';
import i18n from '../i18n';

export const DEBOUNCE_VALIDATION_TIME = 500;

export const REQUEST_TIMEOUT_CODE = 408;
export const NOT_RESPONDING_CODE = 502;

const APOLLO_CLIENT_SERVER_DOWN_MSG = 'Failed to fetch';
const AXIOS_REQUEST_SERVER_DOWN_MSG = 'Network Error';

export const SERVER_UNAVAILABLE_MSG = [
  APOLLO_CLIENT_SERVER_DOWN_MSG,
  AXIOS_REQUEST_SERVER_DOWN_MSG,
];

export const IGNORE_ERROR_MESSAGE_APIS = ['getNotifications'];

export const INFANT_CODE_LEVEL = 'Infant';

export const CASH = 'cash';
export const MFS_HQ = 'mfs_hq';
export const DEFAULT_PRINCIPAL = 'default_principal';
export const DEFAULT_STAFF = 'default_staff';
export const MFS_HQ_LABEL = 'MFS HQ';

export const HOURS_LIST = [
  {
    label: '01',
    description: '01',
  },
  {
    label: '02',
    description: '02',
  },
  {
    label: '03',
    description: '03',
  },
  {
    label: '04',
    description: '04',
  },
  {
    label: '05',
    description: '05',
  },
  {
    label: '06',
    description: '06',
  },
  {
    label: '07',
    description: '07',
  },
  {
    label: '08',
    description: '08',
  },
  {
    label: '09',
    description: '09',
  },
  {
    label: '10',
    description: '10',
  },
  {
    label: '11',
    description: '11',
  },
  {
    label: '12',
    description: '12',
  },
];
export const weekDays = [
  {
    label: 'M',
    value: 1,
  },
  {
    label: 'T',
    value: 2,
  },
  {
    label: 'W',
    value: 3,
  },
  {
    label: 'T',
    value: 4,
  },
  {
    label: 'F',
    value: 5,
  },
  {
    label: 'S',
    value: 6,
  },
  {
    label: 'S',
    value: 0,
  },
];

export const YEARS = (fromYear = null, toYear = null) => {
  const retArr = [];
  const currentDate = new Date();

  if (
    (fromYear && toYear && fromYear > toYear) ||
    (toYear && currentDate.getFullYear() > toYear)
  ) {
    return retArr;
  }

  const startVal = moment().year(fromYear || currentDate.getFullYear());
  const startYear = parseInt(startVal.format('YYYY'), 10);

  while (
    (toYear && parseInt(startVal.format('YYYY'), 10) < parseInt(toYear, 10)) ||
    (!toYear && parseInt(startVal.format('YYYY'), 10) < startYear + 5)
  ) {
    retArr.push({
      label: parseInt(startVal.format('YYYY'), 10),
      value: parseInt(startVal.format('YYYY'), 10),
    });
    startVal.add(1, 'year');
  }

  retArr.push({
    label: parseInt(startVal.format('YYYY'), 10),
    value: parseInt(startVal.format('YYYY'), 10),
  });

  return retArr;
};

export const MONTH_DAYS = (days = null) => {
  const mArr = [];
  for (let idx = 1; idx <= (days || 31); idx++) {
    mArr.push({
      label: idx,
      value: idx,
    });
  }
  return mArr;
};

export const YEAR_MONTHS = [
  {
    label: 'Jan',
    value: 1,
  },
  {
    label: 'Feb',
    value: 2,
  },
  {
    label: 'Mar',
    value: 3,
  },
  {
    label: 'Apr',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'Jun',
    value: 6,
  },
  {
    label: 'Jul',
    value: 7,
  },
  {
    label: 'Aug',
    value: 8,
  },
  {
    label: 'Sep',
    value: 9,
  },
  {
    label: 'Oct',
    value: 10,
  },
  {
    label: 'Nov',
    value: 11,
  },
  {
    label: 'Dec',
    value: 12,
  },
];

export const FIVE_MIN_REPORT_LIST = [
  'trade_debt_report',
  'trade_debt_summary_report',
];

export const NON_EDITABLE_BILLABLE_ITEMS = [
  'discount',
  'financial_assistance',
  'sug',
  'advance_payment',
  'additional_subsidy',
  'sug_wo_deposit',
  'subsidy',
  'co_payment',
  'dsp',
  'dsp_subsidy',
  'dsp_added_subsidy',
];
export const TENMBINBYTES = 10485760;
export const TRADE_DEBT_REPORT_LABEL = 'Trade Debtor Report';
export const TRADE_DEBT_SUMMARY_REPORT_LABEL = 'Trade Debtor Summary Report';
export const CDA_REFUND_EXCEPTION_REPORT_LABEL = 'CDA Refund Exception Report';
export const CHILD_DELAYED_DISMISSAL_REPORT = 'child_delayed_dismissal_report';
export const ENROLMENT_REPORT = 'enrolment_finance_report';
export const GIRO_PARTICIPATION_REPORT = 'giro_participation_report';
export const ECDA_VACANCY_REPORT = 'ecda_vacancy_report';
export const TRANSFER_REPORT = 'list_of_transfer_report';
export const WITHDRAWAL_REPORT = 'list_of_withdrawal_report';
export const TRADE_DEBT_REPORT = 'trade_debt_report';
export const TRADE_DEBT_SUMMARY_REPORT = 'trade_debt_summary_report';
export const CDA_REFUND_EXCEPTION_REPORT = 'cda_refund_exception_report';
export const QQL_CHECKLIST_MIGRATED_REPORT = 'qql_checklist_migrated_report';
export const GRADUATION_LABEL = 'graduation';
export const INTERNAL_TRANSFER_LABEL = 'internal_transfer';

export const CHECKLIST_REPORT = 'checklist_report';
export const MIN_CHECK_LIST_YEAR = 2022;
export const REPORT_OPERATION = 'Operation';

/** Set Min Year as 2014 because data from QQL are from 2014 */
export const MIN_QQL_MIGRATED_CHECKLIST_YEAR = 2014;

export const DELAYED_DISMISSAL_REPORT_START_DATE = '2021-10-01';
export const GST_VALUE = 0.07;
export const NINEMBINBYTESBINARY = 9000000;
export const PRODUCTION = 'production';
export const PROD = 'prod';
export const ACCEPTEDIMAGEFORMAT = '.png, .jpg, .jpeg';
export const ACCEPTEDIMAGEVIDEOFORMAT =
  '.mp4, .mkv, .webm, .wmv, .avi, .png, .jpg, .jpeg';
export const VOIDED = 'Voided';
export const FULLDAY = 'fullday';
export const AM = 'am';
export const PM = 'pm';
export const HOLIDAY = 'holiday';
export const CENTRE_CLOSURE = 'centre_closure';
export const SEARCH_API_TRIGGER_DELAY = 750;
export const CHECK_IN = 'check_in';
export const CHECK_OUT = 'check_out';
export const AVAILABLE = 'available';
export const WAITLIST = 'waitlist';
export const WAITLISTED = 'waitlisted';
export const DATE = 'dddd';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_DAY_FORMAT = 'DD/MM/YYYY DDDD';
export const USER_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_SHORT = `DD MMM`;
export const YEAR_FORMAT = 'YYYY';
export const MONTH_FORMAT = 'MM';
export const SHORT_MONTH_FORMAT = 'MMM';
export const SHORT_MONTH_YEAR = 'MM/YY';
export const MONTH_YEAR_FORMAT = 'MM/YYYY';
export const FULL_MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const FULL_MONTH_FORMAT = 'MMMM';
export const REVERSE_DATE_FORMAT = 'YYYY/MM/DD';
export const SAVE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_PICKER_FORMAT = 'YYYY-MM-DD';
export const DATE_MONTH_YEAR = 'DD-MM-YYYY';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const DATE_VIEW_FORMAT = 'DD MMM YYYY';
export const DATE_MONTH_YEAR_FORMAT = 'DD MMMM YYYY';
export const SHORT_DATE_MONTH_YEAR_FORMAT = 'D MMMM YYYY';
export const DATE_APPOINTMENT_FORMAT = 'ddd, DD MMM YYYY';
export const HR_24_FORMAT = 'HH:mm:ss';
export const HR_12_FORMAT = 'hh:mm:ss';
export const HR_12_SHORT_FORMAT = 'LT';
export const HR_24_SHORT_FORMAT = 'HH:mm';
export const HR_24_AM_PM = 'hh:mm A';
export const HR_12_FORMAT_WITHOUT_SECOND = 'hh:mm';
export const ACTIVITY_DATE = 'DD MMM YYYY • hh:mm A';
export const ACTIVITY_TIME = '• hh:mm A';
export const REPORT_DATE = 'DD/MM/YYYY • hh:mm A';
export const CENTRE_DATE = 'DD/MM/YY • hh:mm A';
export const SHORT_MONTH_YEAR_FORMAT = 'MMM YYYY';
export const DD_MMM_YYYY = 'DD-MMM-YYYY';
export const FULL_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const ACTIVITY_LOG_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const ACTIVITY_LOG_BIRTH_DATE_FORMAT = 'MMMM Do YYYY';
export const RES_START_DATE_PICKER_FORMAT = 'YYYY-MM-DD 00:00:00';
export const RES_END_DATE_PICKER_FORMAT = 'YYYY-MM-DD 23:59:59';
export const CUTOFF_DATE = '2024-03-28 23:59:59'; // March 28, 2024
export const CUTIN_DATE = '2024-01-01 00:00:00'; // Jan 1, 2024
export const DEFAULT_ERROR = 'Something went wrong';
export const UPLOAD_ERROR = 'Something went wrong while uploading';
export const INVALID_FILE = 'Invalid filetype';
export const DEFAULT_COUNTRY_CODE = '+65';
export const NORMAL_TEMPERATURE = 0;
export const ASCENDING = 'asc';
export const DESCENDING = 'desc';
export const APPROVED = 'Approved';
export const SKOOLNET_ERRORS = 'SkoolNet_Error:';
export const errorCss = 'color: #f3575a; font-size: 20px';
export const primaryColor = '#f9a453';
export const secondaryColor = '#75c044';
export const chartHeightColor = '#56C4C5';
export const chartWeightColor = '#F1BE48';
export const UNAUTHORIZED_MSG =
  'You do not have sufficient access to view this';
export const DRAFT = 'draft';
export const REFUND_SUCCEED_STATUS = 'succeed';
export const VENDOR_DECISION_PENDING = 'pending';
export const APPROVAL_PENDING = 'approval_pending';
export const NOT_APPLICABLE = 'not_applicable';
export const RECALLED = 'recalled';
export const PUBLISHED = 'published';
export const ALL_POSTS = 'all_posts';
export const TO_APPROVE = 'to_approve';
export const SENT = 'sent';
export const PAY_NOW = 'pay_now';
export const PAYNOW_QR = 'paynow_qr';
export const BANK_TRANSFER = 'bank_transfer';
export const DEFAULT_NEXT_MONTH = moment().add(1, 'month').startOf('month');
export const GRAPHQL_ERR_INITIAL_TXT = 'GraphQL error: ';
export const ERR_EXCEEDING_CAPACITY = 'ERR_EXCEEDING_CAPACITY';
export const TECHNICAL_ASSISTANCE = 'Technical Assistance';

// export const ENROLLED_CHILD_DOC_MAX_SIZE = 5242880;
export const ENROLLED_CHILD_DOC_MAX_SIZE = 5000000;
export const DISPLAY_NAME_LENGTH = 100;
export const STAFF_ID_LENGTH = 6;
export const STAFF_ID_LENGTH_MAX = 8;
export const REG_EXP_DIGIT = /^\d+$/;
export const ACCEPTED_BROADCAST_FILES = 'image/*,.pdf';
export const ACCEPTED_RESOURCE_FILES =
  '.jpg, .pdf, .png, .xls, .xlsx, .jpeg, .txt, .doc, .docx, .bmp, .csv, .mp4, .mkv, .webm, .wmv, .avi';
export const communicationModes = [
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Meeting',
    value: 'meeting',
  },
  {
    label: 'Others',
    value: 'other',
  },
];

export const ACCESS_TYPE = {
  READ: 'read',
  WRITE: 'write',
  APPROVE: 'approve',
};

export const schoolConfigLabelKeyMap = [
  {
    key: 'logo_url',
    label: 'School Logo',
    radioButton: false,
    imageUpload: true,
    group: 'Branding',
  },
  {
    key: 'login_banner',
    label: 'Login Banner',
    radioButton: false,
    imageUpload: true,
    group: 'Branding',
  },
  {
    key: 'primary_color',
    label: 'Primary Color',
    radioButton: false,
    imageUpload: false,
    group: 'Branding',
  },
  {
    key: 'secondary_color',
    label: 'Secondary Color',
    radioButton: false,
    imageUpload: false,
    group: 'Branding',
  },
  {
    key: 'header_color',
    label: 'Header Color',
    radioButton: false,
    imageUpload: false,
    group: 'Branding',
  },
  {
    key: 'footer_color',
    label: 'Footer Color',
    radioButton: false,
    imageUpload: false,
    group: 'Branding',
  },
  {
    key: 'current_year_enquiry_open_period',
    label: 'Current Year Enquiry Open Period',
    numericField: true,
    radioButton: false,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'enrolment_period_in_mth',
    numericField: true,
    label: 'Enrolment Period in Month',
    radioButton: false,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'enquiry_expiry_in_mth',
    label: 'Enquiry Expiry In Month',
    numericField: true,
    radioButton: false,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'withdraw_notice_in_days',
    label: 'Withdraw Notice in Days',
    numericField: true,
    radioButton: false,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'registration_due_date_days',
    label: 'Registration Due Date in Days',
    numericField: true,
    radioButton: false,
    imageUpload: false,
    hint: 'Set as 0 for no due date',
    group: 'Centre Processes',
  },
  {
    key: 'registration_cutoff_days',
    label: 'Registration Cut off in Days',
    numericField: true,
    radioButton: false,
    imageUpload: false,
    hint: 'Set as 0 for no due date',
    group: 'Centre Processes',
  },
  {
    key: 'tmp_withdrawal_period',
    label: 'Temporary Withdrawal Period in Months',
    numericField: true,
    radioButton: false,
    imageUpload: false,
    hint: 'Temporary Withdrawal Period is not allowed to be `0`',
    group: 'Centre Processes',
  },
  {
    key: 'radius_centre_search',
    label: 'Radius Centre Search',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'force_otp_on_registration',
    label: 'Force OTP on Registration',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'skip_trial',
    label: 'Skip Trial Period',
    radioButton: true,
    imageUpload: false,
    hint: 'Enable/Disable this flag for auto skipping trial visit for all newly created enquiries.',
    group: 'Centre Processes',
  },
  {
    key: 'skip_visit',
    label: 'Skip Centre Visit',
    radioButton: true,
    imageUpload: false,
    hint: 'Enable/Disable this flag for auto skipping centre visit for all newly created enquiries.',
    group: 'Centre Processes',
  },
  {
    key: 'skip_giro_setup_school_visit',
    label: 'Skip Centre Visit - GIRO Form Submission',
    radioButton: true,
    imageUpload: false,
    hint: 'Enable/Disable this flag for parent to SKIP scheduling a centre visit to submit GIRO form',
    group: 'Centre Processes',
  },
  {
    key: 'enable_late_payment_fee_fine',
    label: 'Late Payment',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'ecda_upload_active',
    label: 'Enable ECDA Admission Upload',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'allow_waitlist_visit',
    label: 'Allow Waitlist Visit',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'accept_unborn',
    label: 'Accept Unborn',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'credit_card_payment_enable',
    label: 'Credit Card Payment',
    radioButton: true,
    imageUpload: false,
    hint: 'Enable/Disable credit card payment for parent during registration',
    group: 'Centre Processes',
  },
  {
    key: 'enable_manual_child_cico',
    label: 'Enable Manual Check In/Out',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'skip_waitlist',
    label: 'Skip Waitlist',
    radioButton: true,
    imageUpload: false,
    hint: 'If skip waitlist is enabled, transfer request created will bypass the waitlist offer flow and an enquiry will be automatically added for the child in the background.',
    group: 'Centre Processes',
  },
  {
    key: 'show_create_registration_button',
    label: 'Create Registration Button',
    radioButton: true,
    imageUpload: false,
    hint: 'This will enable/disable the create registration button in registration module.',
    group: 'Centre Processes',
  },
  {
    key: 'enable_exceeding_capacity_check',
    label: 'Enable Exceeding Capacity Check',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'enable_vacancy_check',
    label: 'Enable Vacancy Checking',
    radioButton: true,
    imageUpload: false,
    group: 'Centre Processes',
  },
  {
    key: 'skip_graduation_form',
    label: 'Skip Graduation Form',
    radioButton: true,
    imageUpload: false,
    hint: 'Enable/Disable this flag to skip graduation forms for newly created graduations',
    group: 'Centre Processes',
  },
  {
    key: 'enrichment_notification_parent_child_withdrawal',
    label: 'Enrichment Notification Parents Child Withdrawal',
    radioButton: true,
    imageUpload: false,
    linkField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enrichment_notification_parent_on_price_change',
    label: 'Enrichment Notification Parents On Price Change',
    radioButton: true,
    imageUpload: false,
    linkField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enable_default_ecda_form1_signing',
    label: 'Enable Default ECDA Form 1 Signing',
    radioButton: true,
    imageUpload: false,
    displayText: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'partner_school_url',
    label: 'External LMS URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'infectious_case_url',
    label: 'Infectious case URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'attendance_reports',
    label: 'Attendance Report URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'registration_children_report',
    label: 'Registration Children Report URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'enrolment_withdrawal_dashboard',
    label: 'Enrolment and Withdrawal Dashboard URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'enrolment_planning',
    label: 'Enrolment Planning Report URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'enquiry_report',
    label: 'Enquiry Report URL',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    checkIsRequired: () => false,
    group: 'Links',
  },
  {
    key: 'waitlist_sync_failed_recipient_email',
    label: 'Waitlist Sync Failed Email Recipient',
    radioButton: false,
    imageUpload: false,
    linkField: false,
    textField: true,
    checkIsRequired: isMFS => {
      return isMFS;
    },
    group: 'Links',
  },
  {
    key: 'enrichment_fee_deduction_start_day',
    label: 'Enrichment Fee Deduction Start Day',
    radioButton: false,
    imageUpload: false,
    linkField: false,
    textField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enrichment_vendor_decision_start_date',
    label: 'Enrichment Vendor Decision Start Date',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enrichment_vendor_decision_end_date',
    label: 'Enrichment Vendor Decision End Date',
    radioButton: false,
    imageUpload: false,
    linkField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enrichment_child_next_level_classes',
    label: 'Enrichment Child Next Level Classes Date',
    radioButton: false,
    imageUpload: false,
    linkField: false,
    textField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enrichment_infant_next_level_classes',
    label: 'Enrichment Infant Next Level Classes Date',
    radioButton: false,
    imageUpload: false,
    linkField: false,
    textField: true,
    group: 'Enrichment Programme',
  },
  {
    key: 'enrichment_is_show_classes_on_parent_overview',
    label: 'Enrichment Is Show Classes On Parent Overview',
    radioButton: true,
    imageUpload: false,
    linkField: false,
    textField: false,
    group: 'Enrichment Programme',
  },
];

export const BLACKLISTED = 'blacklisted';
export const DOCUMENT = 'document';
export const PAYMENT = 'payment';
export const ENROLLED = 'enrolled';
export const WITHDRAWN = 'withdrawn';
export const SUSPENDED = 'suspended';
export const REASSIGNED = 'reassigned';
export const PRE_REGISTRATION = 'pre_registration';
export const CONSENT = 'consent';
export const GIRO = 'giro';
export const SUPPLEMENTARY_REGISTRATION = 'supplementary_registration';
export const PENDING_REGISTRATION = 'pending_registration';
export const WAITING_LIST = 'waiting_list';
export const CANCELLED = 'cancelled';
export const EXPIRED = 'expired';
export const DELETED = 'deleted';
export const WITHDRAWAL = 'withdrawal';
export const WITHDRAWAL_REQUEST = 'withdrawal_request';
export const REGISTRATION_FORM = 'Registration Form';
export const FORM_1_OFFLINE_CONSENT =
  'Form 1 - Enrolment and Subsidy Application';
export const THIS_MONTH = 'this_month';
export const NEXT_MONTH = 'next_month';
export const TO_BE_ENROLLED = 'to_be_enrolled';
export const GRADUATED = 'graduated';
export const ENQUIRED = 'enquired';
export const TEMP_WITHDRAWN = 'temporary_withdrawn';
export const SCHEDULED = 'scheduled';
export const ON_GOING = 'ongoing';
export const INVALID_DATE = 'Invalid date';
export const TO_BE_TRANSFERRED = 'to_be_transferred';
export const REGISTRATION_FORM_CODE = 'registration_form';
export const FINANCE_DOCS = 'finance_documents';
export const TEMP_WITHDRAWAL = 'temporary_withdrawal';
export const HQ_USER = 'HQ';
export const BC = 'BC';
export const WITHDRAW = 'withdraw';

export const registrationEditStates = [DOCUMENT, PAYMENT, ENROLLED, CANCELLED];

export const MONTH_OPTIONS = [
  { label: '01', description: i18n.t('common.january') },
  { label: '02', description: i18n.t('common.february') },
  { label: '03', description: i18n.t('common.march') },
  { label: '04', description: i18n.t('common.april') },
  { label: '05', description: i18n.t('common.may') },
  { label: '06', description: i18n.t('common.june') },
  { label: '07', description: i18n.t('common.july') },
  { label: '08', description: i18n.t('common.august') },
  { label: '09', description: i18n.t('common.september') },
  { label: '10', description: i18n.t('common.october') },
  { label: '11', description: i18n.t('common.november') },
  { label: '12', description: i18n.t('common.december') },
];

export const registrationStates = [
  {
    step: 0,
    state: PRE_REGISTRATION,
  },
  {
    step: 0,
    state: PENDING_REGISTRATION,
  },
  {
    step: 1,
    state: SUPPLEMENTARY_REGISTRATION,
  },
  {
    step: 2,
    state: DOCUMENT,
  },
  {
    step: 3,
    state: CONSENT,
  },
  {
    step: 4,
    state: GIRO,
  },
  {
    step: 5,
    state: PAYMENT,
  },
  {
    step: 6,
    state: ENROLLED,
  },
];

export const registrationStatesECDAMVP = [
  {
    step: 0,
    state: PRE_REGISTRATION,
  },
  {
    step: 0,
    state: PENDING_REGISTRATION,
  },
  {
    step: 1,
    state: DOCUMENT,
  },
  {
    step: 2,
    state: GIRO,
  },
  {
    step: 3,
    state: PAYMENT,
  },
  {
    step: 4,
    state: ENROLLED,
  },
];

export const enrolmentStates = [
  PENDING_REGISTRATION,
  DOCUMENT,
  PAYMENT,
  ENROLLED,
];

export const centreVisitStatusMap = {
  created: 'Pending centre visit',
  rejected: 'Centre Visit Rejected',
  completed: 'Centre Visit Approved',
};

export const staffOfRequirementCentres = ['NGEE ANN POLYTECHNIC (NP)'];

export const VACANCY_PLANING_CODE = 'enrollment_planning';
export const CENTRE_MANAGEMENT_CODE = 'centre_management';
export const CLASS_MANAGEMENT_CODE = 'class_management';
export const CLASS_ACTIVITY_CODE = 'class_activity_management';

export const READ_ACCESS = 1;
export const WRITE_ACCESS = 2;
export const APPROVE_ACCESS = 4;

export const MFS_ID = 1;
export const LSH_ID = 2;
export const TCC_ID = 3;
export const schoolIDMap = {
  [MFS_ID]: {
    s3Folder: 'MFS',
    name: 'My First Skool',
    fullName: 'NTUC My First Skool Limited',
    chineseName: i18n.t('common.mfsChineseName'),
    email: 'mfsmarketing@ntucfirstcampus.com',
  },
  [LSH_ID]: {
    s3Folder: 'LSH',
    name: 'Little Skool-House',
    fullName: 'The Little Skool-House International Pte Ltd',
    chineseName: i18n.t('common.lshChineseName'),
    email: 'lshadmissions@littleskoolhouse.com',
  },
  [TCC_ID]: {
    s3Folder: 'TCC',
    name: "The Caterpillar's Cove",
    fullName: "The Caterpillar's Cove",
  },
};

/**
 * Bitwise declarations for bank options
 *  1    1
 * DBS  OCBC
 */

export const OCBC = 1;
export const DBS = 2;
export const GIRO_ACCOUNT_STATUS_PENDING = 'pending';
export const DOCUMENT_ACCESS_PARENT = 1;
export const DOCUMENT_ACCESS_STAFF = 2;
export const DOCUMENT_ACCESS_STAFF_PARENT = 3;
export const GIRO_FORM = 'giro_form';
export const SIBLING_BC = 'sibling_birth_certificate';
export const CDA_STATEMENT = 'cda_account_statement';

/**
 * Giro Error from backend
 */

export const GIRO_ERRORS = {
  CONTACT_BANK: 'ERR002',
  TRY_AGAIN: 'ERR003',
  INVALID: 'ERR004',
  EXPIRED_TOKEN: 'ERR005',
};

/**

 * Document Verification Error from backend

 */

export const DOCUMENT_UPLOAD_ERROR = {
  CODE: 'ERR1001',
};

export const REFUND_CDA = 'Refund to CDA';
export const REFUND_NON_CDA = 'Refund to Non-CDA';
export const OTHER_VISIT_TYPE = 'other';
export const PARENT_VISIT_TYPE = 'visit';
export const PAYMENT_VISIT_TYPE = 'payment';
export const REGISTER_VISIT_TYPE = 'registration_visit';
export const ENROLLMENT = 'enrollment';
export const VISIT_CREATED = 'created';
export const COMPLETED = 'completed';
export const SUCCESSFUL = 'successful';
export const EXPORTED = 'exported';
export const REFUND_COMPLETE = 'Complete';
export const COMPLETE = 'complete';
export const INITIATED = 'initiated';
export const VOID = 'void';
export const MANUAL = 'manual';
export const REJECTED = 'rejected';
export const DISABLED = 'disabled';
export const SUBMITTED = 'submitted';
export const NOT_REQUIRED = 'not_required';
export const CREATED = 'created';
export const MARKED = 'marked';
export const UN_PUBLISHED = 'unpublished';
export const PENDING = 'pending';
export const IN_PROGRESS = 'in_progress';
export const UPLOADING = 'uploading';
export const APPROVE = 'approved';
export const UNSUCCESSFUL = 'unsuccessful';
export const CHECKED = 'checked';
export const PRINCIPAL_APPROVED = 'principal_approved';
export const HQ_REJECTED = 'hq_rejected';
export const HQ_APPROVED = 'hq_approved';
export const BANK_ACC_INFO_SUBMITTED = 'bank_acc_info_submitted';
export const DECLARATION = 'declaration';
export const SOURCE_CENTRE_STAFF_APPROVED = 'source_centre_staff_approved';
export const FAILED = 'failed';
export const FAIL = 'fail';
export const SUCCEED = 'succeed';
export const DEACTIVATED = 'deactivated';
export const ACTIVE = 'active';
export const UPCOMING = 'upcoming';
export const ONLINE = 'online';
export const MISSING_ATTENDANCE = 'missing_attendance';
export const UNVERIFIED = 'unverified';
export const RESCHEDULED = 'rescheduled';
export const RESCHEDULE = 'reschedule';
export const MEDIA = 'media';
export const SELF_EMPLOYED = 'self_employed';
export const SALARIED_EMPLOYEE = 'salaried_employee';
export const SALARIED_AND_SELF_EMPLOYED = 'salaried_and_self_employed';
export const NOT_WORKING = 'not_working';
export const WORKING = 'working';
export const LOOKING_FOR_JOB = 'looking_for_job';
export const PREGNANCY = 'pregnancy';
export const CHILD_CARE = 'child_care';
export const PAID = 'paid';
export const UNPAID = 'unpaid';
export const WAITING_APPROVAL = 'waiting_approval';
export const SEPARATED = 'separated';
export const ENROLMENT_STEP_FINISH = 10;
export const ECDA_ENROLMENT_STEP_FINISH = 7;
export const MAX_NUMBER_FAMILY_MEMBERS = 5;
export const REGISTRATION_CANCEL_MODAL = 'registration_cancel';
export const REGISTRATION_CANCEL_REASONS_MODAL = 'registration_cancel_reasons';
export const REGISTRATION_CANCEL_CONFIRM_MODAL = 'registration_cancel_confirmm';
export const PENDING_PRINCIPAL_REVIEW = 'pending_principal_review';
export const PENDING_WITHDRAWAL_FORM_SUBMISSION =
  'pending_withdrawal_form_submission';
export const VERIFIED = 'verified';
export const ERROR = 'error';
export const SUCCESS = 'success';
export const DATE_LABEL = 'date';
export const DATE_RANGE_LABEL = 'date_range';
export const BUTTON_LABEL = 'button';
export const MULTI_SELECT = 'multi_select';
export const RESET_FILTER = 'reset_filter';
export const CHILD_SEARCH = 'child_search';
export const CUSTOM_FIELD = 'custom_field';
export const WARNING = 'warning';

export const ADMISSION_STATUS = {
  ENQUIRE: 'enquire',
  NEW_ENQUIRY: 'new_enquiry',
  CANCELLED_ENQUIRY: 'cancelled_enquiry',
  ENROLLED_SCHOOL: 'enrolled_school',
  BLACKLISTED: 'blacklisted',
  VISIT: 'visit',
  PENDING_VISIT: 'pending_visit',
  SCHEDULED_VISIT: 'scheduled_visit',
  VISIT_APPROVED: 'visit_approved',
  VISIT_SKIPPED: 'visit_skipped',
  VISIT_REJECTED: 'visit_rejected',
  TRIAL: 'trial',
  PENDING_TRIAL: 'pending_trial',
  SCHEDULED_TRIAL: 'scheduled_trial',
  PENDING_TRIAL_PAYMENT: 'pending_trial_payment',
  TRIAL_APPROVED: 'trial_approved',
  TRIAL_REJECTED: 'trial_rejected',
  ENROLMENT: 'enrolment',
  PRE_REGISTRATION: 'pre_registration',
  PENDING_FORM_COMPLETION: 'pending_form_completion',
  PENDING_DOCUMENT_SUBMISSION: 'pending_document_submission',
  PENDING_DOCUMENT_VERIFICATION: 'pending_document_verification',
  PENDING_ECONSENT: 'pending_econsent',
  PENDING_APPLICANT_CONSENT: 'pending_applicant_consent',
  PENDING_PRINCIPAL_CONSENT: 'pending_principal_consent',
  PENDING_GIRO: 'pending_giro',
  PENDING_REGISTRATION_PAYMENT: 'pending_registration_payment',
  REGISTERED: 'registered',
  CANCELLED_REGISTERED: 'withdrawn_pre_enrolment',
  CANCELLED: 'cancelled',
  PENDING_PAYMENT: 'pending_payment',
  ENROLLED: 'enrolled',
  REGISTRATION_SUBMITTED: 'registration_submitted',
};

export const MOVE_REASON = {
  PROMOTION: 1,
  PROGRAM_CHANGE: 2,
  PROMOTION_PROGRAM_CHANGE: 3,
  WITHDRAWAL: 4,
  TRANSFER: 8,
  GRADUATION: 16,
};

export const CITIZEN = 'citizen';

export const NATIONALITY_OPTIONS = {
  citizen: '@enum:CITIZEN',
  pr: '@enum:PERMANENT_RESIDENT',
  others: '@enum:OTHERS',
};

export const MARITAL_STATUS_OPTIONS = {
  married: '@enum:MARRIED',
  single: '@enum:SINGLE',
  divorced: '@enum:DIVORCED',
  separated: '@enum:SEPARATED',
  widowed: '@enum:WIDOWED',
};

export const WORKING_STATUS_OPTIONS = {
  self_employed: '@enum:SELF_EMPLOYED',
  salaried_employee: '@enum:SALARIED_EMPLOYEE',
  salaried_and_self_employed: '@enum:SALARIED_AND_SELF_EMPLOYED',
  not_working: '@enum:NOT_WORKING',
  working: '@enum:WORKING',
};

export const days = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 4,
  THURSDAY: 8,
  FRIDAY: 16,
  SATURDAY: 32,
  SUNDAY: 64,
};

export const POST_ICON_URL = 'ClassActivity/Icons/icon-post-active.svg';
export const ALBUM_ICON_URL = 'ClassActivity/Icons/icon-album-active.svg';
export const FOLIETTE_ICON_URL =
  'ClassActivity/Icons/icon-observation-active.svg';

export const POST_ICON_URL_WHITE = 'ClassActivity/Icons/icon-post-white.svg';
export const ALBUM_ICON_URL_WHITE = 'ClassActivity/Icons/icon-album-white.svg';
export const FOLIETTE_ICON_URL_WHITE =
  'ClassActivity/Icons/icon-observation-white.svg';

export const POST = 'post';
export const ALBUM = 'album';
export const FOLIETTE = 'foliette';
export const OBSERVATION = 'observation';

export const PARTICULARS = 'particulars';
export const NON_STAFF = 'not_a_staff';

export const WORKPLACE = 'workplace';
export const NON_WORKPLACE = 'non_workplace';

export const GUARDIAN = {
  PARENT: 'parent',
  AUTHORISEDPERSONAL: 'authorisedPersonal',
  AUTHORISEDPERSONALTEXT: 'Temporary Authorised Personnel',
};

export const giroStatus = {
  approved: 'approved',
  disabled: 'disabled',
  pending: 'pending',
  rejected: 'rejected',
};
export const NA_SUPPORT_SCHEME = 'child_support_scheme_na';
export const COMCARE_TERM_ASSISTANCE =
  'child_support_scheme_comcare_term_assistance';
export const HDB_PUBLIC_RENTAL_SCHEME =
  'child_support_scheme_hdb_public_rental_scheme';

export const PURPOSE_OF_VISIT_CODE = 'Purpose_Of_Visit';
export const FIELD_SUPPORT_SCHEME = 'supportScheme';

export const OTHERS = 'Others';

// Currently using the same constanst with backend
export const CHILD_DETAIL = {
  LEVEL_MOVE_REASON: {
    PROMOTION: 1,
    PROGRAM_CHANGE: 2,
    WITHDRAWAL: 4,
    TRANSFER: 8,
    GRADUATION: 16,
    TEMP_WITHDRAWAL: 32,
  },
};

export const NATIONALITY_LABELS = {
  citizen: 'citizen',
  pr: 'pr',
  others: 'others',
};

export const FORM_SIGNING_STATUS = {
  pending: 'Pending',
  in_progress: 'In progress',
  completed: 'Completed',
  invalid: 'Invalid',
  cancelled: 'Cancelled',
};

export const BAD_TEMPERATURE_THRESHOLD = 37.5;

export const SVG_ICONS = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  ALERT: 'alert',
  CANCEL: 'cancel',
  PENDING: 'pending',
  RETURN: 'return',
  EVENT: 'event',
  SCHEDULED: 'scheduled',
  CLOSURE: 'closure',
  BUS: 'bus',
  PROMOTION: 'promotion',
  DOCUMENTT: 'document',
  HEALTH: 'health',
  ANNOUNCEMENT: 'announcement',
  PHOTO: 'photo',
};

export const REGISTRATION_FEE = 'registration_fee';

export const OFFLINE_CONSENT_DIR = 'offlineConsent';

export const PRINCIPAL_ROLE = 'Principal';
export const CENTRE_SUPPORT_ROLE = 'Centre Support';
export const ROLE_L1_SUPPORT_LEAD = 'L1 Support Lead Role';
export const SUPER_ADMIN_ROLE = 'BU Leads';
export const BC_USER = 'BC User';
export const ADMIN_ROLE = 'Admin';
export const FINANCE = 'Finance';
export const HQ_ROLE = 'HQ';
export const VENDOR_ADMIN = 'Enrichment Admin';
export const VENDOR_TRAINER = 'Enrichment Trainer';
export const SUPPORT_ROLE = 'Support Role';
export const SENIOR_TEACHER_ROLE = 'Senior Teacher';
export const BC_USER_WITH_ENROLMENT = 'BC User (with Enrolment)';
export const HQ_WITH_ENROLMENT = 'HQ (with Enrolment)';
export const HQ_ADMIN_SUPPORT = 'HQ Admin Support';
export const CSS_ROLE = 'CSS';
export const ENROLMENT_ADVISOR = 'Enrolment Advisor';
export const TECH_SUPER_ADMIN_ROLE = 'Tech Super Admin';
export const HQ_BU_SUPPORT = 'HQ BU Support';
export const CX_CONSULTANT = 'CX Consultant';
export const HQ_CENTRE_SUPPORT = 'HQ Centres’ Support';

export const VERIFYING_ROLE_OPTIONS = [
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  HQ_ROLE,
  PRINCIPAL_ROLE,
  TECH_SUPER_ADMIN_ROLE,
];

export const VENDOR_ROLE_OPTIONS = [VENDOR_ADMIN, VENDOR_TRAINER];

export const HQ_SUPER_ADMIN_ROLE_OPTIONS = [
  TECH_SUPER_ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  HQ_ROLE,
];
export const SUPER_ADMIN_HQ_FINANCE_ROLE_OPTIONS = [
  SUPER_ADMIN_ROLE,
  HQ_ROLE,
  FINANCE,
  TECH_SUPER_ADMIN_ROLE,
];

export const HQ_BU_SUPPORT_ROLE_CREATION_OPTIONS = [
  'default_hq',
  'centre_support',
  'default_principal',
  'senior_teacher',
  'default_staff',
  'Support Role',
  'default_teacher',
  'assistant_teacher',
];

export const MIME_FILES = {
  IMAGE: 'image/jpeg',
  VIDEO: 'video/mp4',
};

// Localization variables
export const CONSENT_USER_ROLES = {
  main_applicant: 'common.mainApplicant',
  main_applicant_guardian: 'registration.consentGuardianLable',
  spouse: 'common.spouse',
  spouse_guardian: 'registration.consentGuardianLable',
  family_member: 'registration.consentFamilyMemberLabel',
  default_staff: 'common.principal',
  default_principal: 'common.principal',
  centre_support: 'Centre Support',
  default_hq: 'HQ',
  senior_teacher: 'Senior Teacher',
  mfs_hq: MFS_HQ_LABEL,
};

export const DATE_TIME_FORMAT = {
  YYYY_MM_DD_HHmmss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH-mm-ss',
  ANNOUNCEMENT_FORMAT: 'DD MMM YYYY • hh:mm A',
};

export const SNACKBAR_HIDE_TIMEOUT = 2000; // miliseconds
export const DEFAULT_TIMEOUT = 3000;

export const SCHOOL_LEVEL_USER_ROLE_LABELS = {
  SUPER_ADMIN: 'BU Leads',
  HQ: 'HQ',
  BC_USER: 'BC User',
  FINANCE: 'Finance',
  BC_USER_WITH_ENROLMENT: 'BC User (with Enrolment)',
  HQ_WITH_ENROLMENT: 'HQ (with Enrolment)',
  HQ_ADMIN_SUPPORT: 'HQ Admin Support',
  HQ_BU_SUPPORT: 'HQ BU Support',
  CSS: 'CSS',
  PRINCIPAL: 'Principal',
  CENTRESUPPORT: 'Centre Support',
  LSHADMIN: 'Admin',
};

export const REGISTRATION_ERROR_CODES = {
  INVALID_STATE_TRANSITION: 'ERR_INVALID_REGISTRATION_STATE_TRANSITION',
};

export const DEFAULF_BROKEN_IMAGE = `${process.env.REACT_APP_CDN_URL}/Assets/default_broken_image.png`;
export const RELATIONS_CHILD = {
  MOTHER: 'MO',
  FATHER: 'FA',
  MSF_PARENT: 'FM',
  CHILDREN_HOME: 'CH',
  LEGAL_GUARDIAN: 'LG',
  NON_PARENT: 'GD',
};

export const PARENT_MARIRAL_STATUS = {
  MARRIED: 'married',
};

export const STUDENT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const ENQUIRY_STATUS = {
  WAITLISTED: 'WAITLISTED',
  WAITLISTED_VACANCY: 'WAITLISTED - VACANCY',
  AVAILABLE: 'AVAILABLE',
  OFFERED: 'OFFERED',
  ASSIGNED: 'ASSIGNED',
  CANCELED: 'CANCELLED',
  CLOSED: 'CLOSED',
  SPECIAL_HANDLING: 'SPECIAL HANDLING',
  PURGED: 'PURGED',
};

export const DAY_OPTIONS = [
  {
    ID: 1,
    label: 'monday',
    description: 'Monday',
  },
  {
    ID: 2,
    label: 'tuesday',
    description: 'Tuesday',
  },
  {
    ID: 3,
    label: 'wednesday',
    description: 'Wednesday',
  },
  {
    ID: 4,
    label: 'thursday',
    description: 'Thursday',
  },
  {
    ID: 5,
    label: 'friday',
    description: 'Friday',
  },
  {
    ID: 6,
    label: 'saturday',
    description: 'Saturday',
  },
  {
    ID: 7,
    label: 'sunday',
    description: 'Sunday',
  },
];

export const PROGRAM_TYPES = [
  {
    type: 'full_day',
    label: 'Full Day Programme Type',
  },
  {
    type: 'half_day_am',
    label: 'Half Day (AM) Programme Type',
  },
  {
    type: 'half_day_pm',
    label: 'Half Day (PM) Programme Type',
  },
];

export const ASSETSIN_TODO = 'todo';
export const ASSETSIN_INPROGRESS = 'inProgress';
export const ASSETSIN_COMPLETE = 'completed';
export const ASSETSIN_PROCESSING = 'processing';
export const MAXSIZE10MB = '104857600';

export const WAITLIST_PARAMETER_TYPE = {
  AUTO: 'Auto',
  MANUAL: 'Manual',
};

export const GENDER_OPTIONS = [
  {
    ID: 0,
    label: 'm',
    description: 'Male',
  },
  {
    ID: 1,
    label: 'f',
    description: 'Female',
  },
];

export const GENDER_DISPLAY = {
  m: 'Male',
  f: 'Female',
};

export const SGT_TIME_OFFSET = 480;

export const CHECKLIST_TYPE = {
  LETTER_INDENTIFICATION: 'multi-literacy',
  MULTILITERACY: 'letter-identification',
  LANGUAGE_FUNCTION: 'language-function',
  SEMESTER: 'semester-checklist',
  HIGH_FREQUENCY: 'high-frequency',
  READING_BEHAVIOR: 'reading-behavior',
  SIGHT_WORD_CHECKLIST: 'sight-word-checklist',
  LITERACY_TRACKING_SECTION_1: 'literacy-tracking-section-1',
  LITERACY_TRACKING_SECTION_2: 'literacy-tracking-section-2',
  GRAPHME_PHONEME_CORRESPONDANCE: 'grapheme-phoneme-correspondence',
  READING_AND_WRITING_DEVELOPMENT: 'reading-and-writing',
  LETTER_AND_SOUND_INDENTIFICATION: 'letterIdentificationChecklistLSH',
};

export const TERM_CODE_1 = 'term1';
export const REDIRECT_TIMEOUT = 1000;
export const MEDIA_FETCH_DELAY = 1000;

export const VULNERABILITY_PROGRAMME_URL =
  'https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme';

// Service Levels
export const K1 = 'K1';
export const K2 = 'K2';
export const N1 = 'N1';
export const N2 = 'N2';
// MFS
export const MFS_NURSERY = 'N2';
export const MFS_TODDLER = 'Playgroup';
/**
 * End
 */

export const ASSISTANT_TEACHER = 'Assistant Teacher';

export const ERR_ENRICHMENT_MAX_SIZE = 'ERR_ENRICHMENT_CLASS_MAX_SIZE_REACHED';
export const OTHER_CODE = 'O';
export const DEFAULT_PAGE = 1;
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const DEFAULT_VARCHAR_TEXT_COUNT = 255;

export const MAX_SELECTABLE_DATE = '01/01/2100';
export const MIN_SELECTABLE_DATE = '01/01/1900';

export const CHECKLIST_TYPE_KEYS = {
  LETTER_INDENTIFICATION: 'letter',
  MULTILITERACY: 'multiLiteracy',
  LANGUAGE_FUNCTION: 'languageFunction',
  SEMESTER: 'semester',
  HIGH_FREQUENCY: 'highFrequency',
  READING_BEHAVIOR: 'readingBehavior',
  LITERACY_TRACKING_SECTION_1: 'literacyTrackingSection1',
  LITERACY_TRACKING_SECTION_2: 'literacyTrackingSection2',
};

export const PORTFOLIO_PDF_COLORS = {
  MFS: {
    aboutMecontainer: '#7C7B7B',
  },
  LSH: {
    aboutMecontainer: '#50C6E1',
  },
};

export const REGEX_CHINESE =
  /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
export const REGEX_TAMIL = /[\u0b80-\u0bff][\u0bbe-\u0bcd\u0bd7]?/;
export const REGEX_QUOTE_STR = /(["'’])(.*?[^\\])\1/g;
export const REGEXT_REGULAR_EXPRESSION =
  /([a-zA-Z:]+|[\u4E00-\u9FFF]+|[\u0B80-\u0BFF]+)/g;
export const PORTFOLIO_PDF_LINE_CHART_HEIGHT = 200;
export const PORTFOLIO_PDF_LINE_CHART_WIDTH = 500;
export const PORTFOLIO_PDF_LINE_DEFAULT_STEP_SIZE = 10;
export const PORTFOLIO_PDF_LINE_DEFAULT_MIN = 0;
export const PORTFOLIO_PDF_LINE_DEFAULT_MAX = 100;
export const PORTFOLIO_PDF_NOTO_SANS_SC_FONT = 'Noto Sans SC';
export const PORTFOLIO_PDF_NOTO_SANS_TAMIL_FONT = 'Noto Sans Tamil';
export const PORTFOLIO_PDF_WORD_LENGTH = 20;
export const PORTFOLIO_PDF_TAMIL_WORD_LENGTH = 12;
export const REGEX_NEW_LINE = /[\n]/g;
export const WEEKLY_ENROLMENT_OPTIONS = [
  { label: 'FullMonth', description: 'centre.enrolmentStartOfMonth', value: 1 },
  {
    label: 'QuarterMonth',
    description: 'centre.enrolmentFirstQuarterOfMonth',
    value: 2,
  },
  { label: 'MidMonth', description: 'centre.enrolmentMidOfMonth', value: 4 },
  {
    label: 'ThreeQuarterMonth',
    description: 'centre.enrolmentThreeQuarterOfMonth',
    value: 8,
  },
];

export const ENROLMENT_PLANNING_ROLL_UP_VIEW =
  'enrolment_planning_roll_up_view_report';

export const REPORT_BY_MONTHS_CATEGORIES = [
  {
    name: ENROLMENT_PLANNING_ROLL_UP_VIEW,
    maxMonths: 12,
  },
];

export const DASHBOARD_REQUEST_KEYS = {
  TRANSFER_DRAFT: 'transferDrafts',
  PROMOTION_BY_LEVEL: 'promotionGroupByLevel',
  WITHDRAWAL_DRAFT: 'withdrawalDrafts',
  PROMOTION_SIGNED: 'promotionSigned',
};

export const DASHBOARD_SORT_OPTIONS = {
  TRANSFER_DRAFT: [
    {
      label: 'updatedAt',
      description: i18n.t('principalDashboard.requestDate'),
      fieldValuePath: 'updatedAt',
    },
    {
      label: 'transferDraftItem.effectiveDate',
      description: i18n.t('common.effectiveDate'),
      fieldValuePath: 'transferDraftItem.effectiveDate',
    },
  ],
  PROMOTION_BY_LEVEL: [
    { label: 'label', description: i18n.t('principalDashboard.centreName') },
  ],
  WITHDRAWAL_DRAFT: [
    {
      label: 'createdAt',
      fieldValuePath: 'withdrawalDraftItem.createdAt',
      description: i18n.t('principalDashboard.requestDate'),
    },
    {
      label: 'effectiveDate',
      description: i18n.t('common.effectiveDate'),
      fieldValuePath: 'withdrawalDraftItem.effectiveDate',
    },
  ],
  PROMOTION_SIGNED: [
    {
      label: 'form.createdAt',
      fieldValuePath: 'upcomingLevel.forms.data.0.createdAt',
      description: i18n.t('principalDashboard.requestDate'),
    },
    {
      label: 'child_level.from',
      fieldValuePath: 'upcomingLevel.from',
      description: i18n.t('common.effectiveDate'),
    },
  ],
};

export const ALL_LABEL = {
  ID: '',
  label: '',
  description: 'All',
};

export const BU = 'BU';
export const SE = 'SE';
export const SIZEINKB = 1024 ** 1;
export const CENTRE_GAME_TO_HIDE = '语文游戏';

export const REGISTRATION_IN_PROCESS_STATUS = [
  ADMISSION_STATUS.PRE_REGISTRATION,
  ADMISSION_STATUS.PENDING_FORM_COMPLETION,
  ADMISSION_STATUS.PENDING_DOCUMENT_SUBMISSION,
  ADMISSION_STATUS.PENDING_DOCUMENT_VERIFICATION,
  ADMISSION_STATUS.PENDING_APPLICANT_CONSENT,
  ADMISSION_STATUS.PENDING_PRINCIPAL_CONSENT,
  ADMISSION_STATUS.PENDING_GIRO,
  ADMISSION_STATUS.PENDING_PAYMENT,
];

export const REGISTRATION_TYPE = {
  transfer: 'transfer',
  nonTransfer: 'non_transfer',
};

export const ENROLMENT_PLANNING_LINKS = {
  VacanciesOffered: 'VacanciesOffered',
  RegistrationsInProcess: 'RegistrationsInProcess',
  RegistrationsCompleted: 'RegistrationsCompleted',
  Withdrawal: 'Withdrawal',
  TransferIn: 'TransferIn',
  TransferOut: 'TransferOut',
  PromotionIn: 'PromotionIn',
  PromotionOut: 'PromotionOut',
  EstPromotionIn: 'EstPromotionIn',
  EstPromotionOut: 'EstPromotionOut',
  Enrollments: 'Enrollments',
  ConsumedCapacity: 'ConsumedCapacity',
  Waitlisted: 'Waitlisted',
};

export const GUARDIAN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

// We might use these 2 configs from database & remove it later
export const LEARNING_GOAL_OLD_VERSION = 1;
export const LEARNING_GOAL_LATEST_VERSION = 2;

export const CENTRE_WORKPLACE_TYPES = [
  {
    ID: 1,
    label: NON_WORKPLACE,
    description: 'Non Workplace',
  },
  {
    ID: 2,
    label: WORKPLACE,
    description: 'Workplace',
  },
];

export const AUTO_PROMOTION_JOB_DAY = 20;
export const AUTO_PROMOTION_JOB_MONTH = 12;
export const AUTO_PROMOTION_REMINDER_DAYS = 30;
export const AUTO_PROMOTION_REMINDER_DAY_OPTIONS = [5, 15, 30, 45];
export const EXCLUDE_PROMOTION_DATES = [
  { day: 1, month: 1 },
  { day: 29, month: 2 },
  { day: 31, month: 12 },
];

export const UNAUTHORISED_LINKPASS_CODE =
  'ERR_UNAUTHORISED_LINKPASS_ASSOCIATION_DELETED';

export const CHILD_ATTENDANCE_TEMPERATURE_REPORT =
  'child_attendance_temperature_report';

export const CHILD_AT_WORK = 'child_at_work';
export const CHILD_WORK_SAMPLE = 'child_work_sample';

export const CHILD_ATTENDANCE_REPORT = 'child_attendance_report';

export const REACT_API_URL_PATH = '/common';
export const CKL_SUMMARY_MAX_LENGTH = 4000;

export const CHILD_DEFAULT_BROKEN_IMAGE =
  'https://s3-ap-southeast-1.amazonaws.com/skoolnet-dev/ChildProfile/profile_pic_default.png';

export const HQ_AND_SUPPER_ADMIN_ROLES = [
  SUPER_ADMIN_ROLE,
  HQ_ROLE,
  BC_USER,
  FINANCE,
  BC_USER_WITH_ENROLMENT,
  HQ_WITH_ENROLMENT,
  HQ_ADMIN_SUPPORT,
  CSS_ROLE,
  ENROLMENT_ADVISOR,
  TECH_SUPER_ADMIN_ROLE,
  HQ_BU_SUPPORT,
];
export const LATEST_CHECKLIST_VERSION = 2;
export const CHECKLIST_VERSION_1 = 1;
export const CHD_REQUIREMENT_YEAR = 2023;
export const MAXIMUM_CLASS_TEACHER_PORTFOLIO_SELECTION = 8;
export const SUPER_ADMIN_ROLES = [SUPER_ADMIN_ROLE, TECH_SUPER_ADMIN_ROLE];
export const CSS_ROLES = [CSS_ROLE];
export const PORTFOLIO_PDF_DISPLAY_DATE_FORMAT = 'DD MMM YYYY (dddd)';
export const PORTFOLIO_LAST_SYNC_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';

export const CENTRE_STATUS = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  PENDING: 'pending',
  INPROCESS: 'inprocess',
  ERROR: 'error',
  INACTIVE: 'inactive',
};

export const HIDE_MODULES_BY_ROLES = {
  enrolment_advisor: ['Withdrawals and Graduations'],
};

export const UNAUTHORIZED_TEXT = 'ERR_UNAUTHORIZED';

export const AuthorizedRoleForCreateorUpdateCentre = {
  MFS: [HQ_BU_SUPPORT, SUPER_ADMIN_ROLE, TECH_SUPER_ADMIN_ROLE],
  LSH: [HQ_ROLE, SUPER_ADMIN_ROLE, TECH_SUPER_ADMIN_ROLE],
};
export const AuthorizedRoleForUpdateCentre = [HQ_ROLE, TECH_SUPER_ADMIN_ROLE];

export const FIVE_MB = 5242880;
export const TEN_MB = 10485760;
export const LICENCE_DURATION_MONTH_LIMIT = 36;
export const CENTRE_DRAFT_TAB_VALUE = 1;
export const CENTRE_DRAFT_SUBMIT_TYPE = 'draft';
export const MIN_CHECKLIST_YEAR_LSH = 2023;
export const CENTRE_SEARCH_TIMER = 200;
export const MFS_SPARK_ID = 3770;
export const MFS_CERTIFICATION_DEFAULT_ID = 3769;
export const LHS_SPARK_ID = 3773;
export const LHS_CERTIFICATION_DEFAULT_ID = 3772;
export const PSS_QUESTION_TYPE_2 = '2';
export const PSS_QUESTION_TYPE_3 = '3';
export const PSS_QUESTION_TYPE_5 = '5';
export const PSS_QUESTION_TYPE_11 = '11';
export const PSS_QUESTION_TYPE_TEXT = 'text';
export const PSS = 'pss';
export const CHARACTER_LIMIT_5000 = 5000;
export const SURVEY_BODY = 'survey_body';
export const ENROLLMENT_PLANING_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const PROGRAM_TYPES_FOR_ENROLMENT_LOG = [
  'Full Day',
  'Half Day (AM)',
  'Half Day (PM)',
];

export const EVALUVATION_NOT_APPLICABLE = 'Not Applicable';
export const CHINESE_EVALUVATION_NOT_APPLICABLE = '暂不适用';
export const DEVELOPMENTAL_AREA = 'developmental_area';
export const LESSON_AREA = 'lesson_area';
export const SECONDS = 60;
export const FEATURE_FLAGS = {
  MS_ATTENDANCE_DISABLED: 'ms_attendance_portal_disabled',
  MS_PHASE_2B_CLASS_ACTIVITY_DISABLED:
    'ms_phase_2b_class_activity_portal_disabled',
  MS_IMAGE_SERVICE_DISABLED: 'new_image_service_disabled',
  MAINTENANCE_PAGE_REDIRECTION: 'fe_maintenance_page_redirection_enabled',
  MS_PHASE_2C_PIM_PSS_DISABLED: 'ms_phase_2c_pim_pss_portal_disabled',
};

export const CENTRE_DOCUMENT_TYPE = {
  LICENSED_INFANT: 'licensed_infant_care_capacity',
  LICENSED_CHILD: 'licensed_child_care_capacity',
  LICENSE_RENEWAL: 'license_renewal',
  SPARK_CERTIFICATION: 'spark_certification',
};

export const CENTRE_DOCUMENT_CATEGORY_OPTIONS = [
  {
    ID: 1,
    key: CENTRE_DOCUMENT_TYPE.LICENSED_INFANT,
    label: 'centre.licensedInfantDocCategory',
  },
  {
    ID: 2,
    key: CENTRE_DOCUMENT_TYPE.LICENSED_CHILD,
    label: 'centre.licensedChildDocCategory',
  },
  {
    ID: 3,
    key: CENTRE_DOCUMENT_TYPE.LICENSE_RENEWAL,
    label: 'centre.licenseRenewalDocCategory',
  },
  {
    ID: 4,
    key: CENTRE_DOCUMENT_TYPE.SPARK_CERTIFICATION,
    label: 'centre.sparkCertificationDocCategory',
  },
];

export const WORKING_STATUS = [
  {
    label: 'resigned',
    description: 'Resigned',
  },
  {
    label: 'not_resigned',
    description: 'Not Resigned',
  },
];

export const ECDA_REGISTRATION_STATUS = [
  'pre_registration',
  'pending_form_completion',
  'pending_document_submission',
  'pending_document_verification',
  'pending_giro',
  'pending_payment',
  'registered',
  'enrolled_school',
  'cancelled',
  'cancelled_registered',
];

export const CREATE_ENROLMENT_FORM_FULLFILLED = 15;
export const CENTRE_OPS_REPORT_DATE_FORMAT = 'YYYY-MM-DD 00:00:00';
export const CHILD_DAILY_LOG_CODE = 'child_daily_log';
export const STAFF_DAILY_LOG_CODE = 'staff_daily_log';
export const CENTRE_EMAIL = 'centre_email';
export const APP_TARGET_ID_PARENT_PORTAL = 2;
export const APP_TARGET_ID_STAFF_PORTAL = 4;
export const MEDIA_DATA = {
  RESIZE: 'resize',
  FIT_CENTER: 'fitcenter',
  RES_480x0: '480x0',
  RES_480x480: '480x480',
  RES_540x0: '540x0',
  RES_540x540: '540x540',
};
export const CENTRE_OPS_RP_CATALOG_CENTRE_OPERATIONS = 'Centre Operations';
export const CENTRE_OPS_RP_CATALOG_CHECKLIST = 'Curriculum';
export const CHECKLIST_REPORT_TYPES = {
  SEMESTER_CHECKLIST_REPORT: 'semester_checklist_report',
  LETTER_ID_CHECKLIST_REPORT: 'letter_id_checklist_report',
  HIGH_FREQUENCY_CHECKLIST_REPORT: 'high_frequency_checklist_report',
  MULTI_LITERACY_CHECKLIST_REPORT: 'multi_literacy_checklist_report',
  LANGUAGE_FUNCTION_ASSESSMENT_CHECKLIST_REPORT:
    'language_function_assessment_checklist_report',
  GRAPHEME_PHONEME_CHECKLIST_REPORT: 'grapheme_phoneme_checklist_report',
  SIGHT_WORD_CHECKLIST_REPORT: 'sight_word_checklist_report',
  READING_BEHAVIOUR_CHECKLIST_REPORT: 'reading_behaviour_checklist_report',
  READING_AND_WRITING_CHECKLIST_REPORT: 'reading_and_writing_checklist_report',
};
export const ALL_USERS = 'all';
export const CLASS_ACTIVITY_IMAGE_MAX_WIDTH_PORTRAIT_TEXT =
  'class_activity_image_max_width_portrait';
export const CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_PORTRAIT_TEXT =
  'class_activity_image_max_height_portrait';
export const CLASS_ACTIVITY_IMAGE_MAX_WIDTH_LANDSCAPE_TEXT =
  'class_activity_image_max_width_landscape';
export const CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_LANDSCAPE_TEXT =
  'class_activity_image_max_height_landscape';
export const CLASS_ACTIVITY_IMAGE_COMPRESSION_PERCENT_TEXT =
  'class_activity_image_compression_percent';
export const CLASS_ACTIVITY_IMAGE_MAX_WIDTH_PORTRAIT = 2400;
export const CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_PORTRAIT = 3300;
export const CLASS_ACTIVITY_IMAGE_MAX_WIDTH_LANDSCAPE = 3300;
export const CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_LANDSCAPE = 2400;
export const CLASS_ACTIVITY_IMAGE_COMPRESSION_PERCENT = 44;
export const CLASS_ACTIVITY_IMAGE_COMPRESSION_FORMAT = 'JPEG';
export const ECDA_ENROLMENT_FORM_STEPS = {
  CHILD_DETAIL: 1,
  MAIN_APPLICANT: 2,
  SPOUSE: 3,
  SUBSIDY_APPLICATION: 4,
  EMERGENCY_CONTACT: 5,
  HELTH_INFO: 6,
};

export const NON_CDA_BANKS = [
  {
    code: 'dbs',
    name: 'DBS BANK LTD/POSB',
  },
  {
    code: 'ocbc',
    name: 'OVERSEA-CHINESE BANKING CORPN LTD',
  },
  {
    code: 'uob',
    name: 'UNITED OVERSEAS BANK LTD',
  },
  {
    code: 'citibank',
    name: 'CITIBANK SINGAPORE LIMITED',
  },
  {
    code: 'sc',
    name: 'STANDARD CHARTERED BANK (S) LTD',
  },
  {
    code: 'hsbc',
    name: 'HSBC BANK (SINGAPORE) LTD',
  },
  {
    code: 'maybank',
    name: 'MAYBANK SINGAPORE LIMITED',
  },
  {
    code: 'boc',
    name: 'BANK OF CHINA LIMITED',
  },
  {
    code: 'icbc',
    name: 'INDUSTRIAL & COMMERCIAL BANK OF CHINA',
  },
  {
    code: 'other',
    name: 'other',
  },
];

export const HHI_ACCESS_ROLES = [
  TECH_SUPER_ADMIN_ROLE,
  SUPPORT_ROLE,
  ROLE_L1_SUPPORT_LEAD,
  CSS_ROLE,
  CENTRE_SUPPORT_ROLE,
  HQ_ADMIN_SUPPORT,
  HQ_BU_SUPPORT,
  HQ_CENTRE_SUPPORT,
  PRINCIPAL_ROLE,
];
export const SPLIT_VALUE_OFF = 'off';
export const SPLIT_VALUE_ON = 'on';

export const DEFAULT_DD_DEPLOY_ENV = 'develop';
export const DEFAULT_DD_API_URL = 'https://dev.api.skool.sg/common';
export const DEFAULT_DD_SCHOOL_ID = 1;
export const DEFAULT_DD_IS_EP = false;

export const DATA_DOG_ENV = [
  {
    env: 'dev18',
    value: 'mfs-sn2-staging',
  },
  {
    env: 'dev19',
    value: 'lsh-sn2-staging',
  },
  {
    env: 'stg',
    value: 'mfs-sn2-sit',
  },
  {
    env: 'devy',
    value: 'lsh-sn2-sit',
  },
  {
    env: 'dev',
    value: 'mfs-sn2-dev',
  },
  {
    env: 'dev2',
    value: 'lsh-sn2-dev',
  },
  {
    env: 'mfs-prod',
    value: 'mfs-sn2-prod',
  },
  {
    env: 'lsh-prod',
    value: 'lsh-sn2-prod',
  },
  {
    env: 'mfs-ep-prod',
    value: 'mfs-ep-prod',
  },
];
